import type {ChartInput} from '@github/insights-client-cloud-embedded'
import {controller} from '@github/catalyst'
import {render} from '@github/insights-client-cloud-embedded'

// when we go to production, this css file is moved out of the InsightsGraphElement scope, so we need to find it and bind to shadowRoot
function findInsightsCss() {
  const sheetList = document.querySelectorAll<HTMLLinkElement>("link[rel='stylesheet']")

  for (const item of sheetList) {
    if (item.href.match(/\/assets\/insights.*\.css$/)) {
      return item
    }
  }
}

@controller
class InsightsGraphElement extends HTMLElement {
  async connectedCallback() {
    // if the parse fails, let the exception bubble up
    const data: ChartInput = JSON.parse(this.getAttribute('data-json')!)
    const tokenUrl = this.getAttribute('data-url')!
    const shadowRoot = this.attachShadow({mode: 'open'})
    const stylesheet = findInsightsCss()

    await render(shadowRoot, tokenUrl, data)

    if (stylesheet) {
      shadowRoot.appendChild(stylesheet)
    }
  }
}
